declare module '#app' {
  interface NuxtApp {
    $handleLink: (url: string, query?: string) => void
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $handleLink: (url: string) => void
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  nuxtApp.provide('handleLink', (url: string) => {
    if (url.startsWith('http')) {
      setTimeout(() => {
        window.open(url, '_blank')
      })
    }

    if (url.startsWith('/')) {
      router.push(url)
    }
  })
})
