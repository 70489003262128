import Cookie from 'cookie-universal'
const cookies = Cookie()

const TOKEN_COOKIE_KEY = 'accessToken'
const REFRESH_TOKEN_COKIE_KEY = 'refresh_token'

export function getToken() {
  return cookies.get(TOKEN_COOKIE_KEY)
}

export function getRefreshToken() {
  return cookies.get(REFRESH_TOKEN_COKIE_KEY)
}

export function apolloHelperLogin(token: string, refreshToken?: string) {
  if (!token) throw new Error('Token is required to login.')

  cookies.set(TOKEN_COOKIE_KEY, token, {
    path: '/',
    secure: false,
    sameSite: 'none',
  })

  if (refreshToken) {
    cookies.set(REFRESH_TOKEN_COKIE_KEY, refreshToken, {
      path: '/',
      secure: false,
      sameSite: 'none',
    })
  }
}

export function apolloHelperLogout() {
  cookies.remove(TOKEN_COOKIE_KEY)
  cookies.remove(REFRESH_TOKEN_COKIE_KEY)
}
