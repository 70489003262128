import { default as entryFAEYo0Hgl5Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/__native/entry.vue?macro=true";
import { default as paymentmzYKAfEhRsMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/__native/payment.vue?macro=true";
import { default as updateCCbkFr5AbhMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/__native/update.vue?macro=true";
import { default as queryPARyOvqm8AMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/4865/query.vue?macro=true";
import { default as __monthlyKPQzZNCxNqMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/__monthly.vue?macro=true";
import { default as __payment8c2oTPaR2qMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/__payment.vue?macro=true";
import { default as index8JJmO0nkDVMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/__preview/index.vue?macro=true";
import { default as indexl4dVqCHEU5Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/card/index.vue?macro=true";
import { default as landingawxZfdUZkWMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/card/landing.vue?macro=true";
import { default as membership7yQoP0k604Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/card/membership.vue?macro=true";
import { default as index2HMrHOJWJPMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/index.vue?macro=true";
import { default as _91id_93CIwI4xzjmrMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/add/[id].vue?macro=true";
import { default as indexN5m5xyROCRMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/add/index.vue?macro=true";
import { default as contactA0rHPUFxnIMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/contact.vue?macro=true";
import { default as faqg6OAt6xUEvMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/faq.vue?macro=true";
import { default as indexZeQ2COaghRMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/index.vue?macro=true";
import { default as contractyHFiSrcEgYMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/input/contract.vue?macro=true";
import { default as loadingBl8856E5lwMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/input/loading.vue?macro=true";
import { default as _91id_93G1xk023QKaMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/transfer/[id].vue?macro=true";
import { default as completeR3nZJ4egBIMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/registry-transcript/complete.vue?macro=true";
import { default as indexfHFkVPRmYJMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/registry-transcript/index.vue?macro=true";
import { default as input_45addressIaUwDTfvzwMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/registry-transcript/input-address.vue?macro=true";
import { default as indextuPefS0LXDMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/coupons/[id]/index.vue?macro=true";
import { default as index3Lp9tMMLIkMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/home/index.vue?macro=true";
import { default as _91id_93MICKojKl8xMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/home/posts/[id].vue?macro=true";
import { default as indexrRTRKXaVIZMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/home/posts/index.vue?macro=true";
import { default as slide3hNmF7rsSDMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/home/posts/slide.vue?macro=true";
import { default as indexGhB8o4rKUmMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/index.vue?macro=true";
import { default as _91id_93xH5ARBmOKsMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/klass/[id].vue?macro=true";
import { default as alarmd9nNzybKRGMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/alarm.vue?macro=true";
import { default as _91contentId_93RfcbsbNyx4Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/certification-of-content/[contentId].vue?macro=true";
import { default as indexrB6D0NRIBUMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/certification-of-content/index.vue?macro=true";
import { default as infoSXRbnDVbxdMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/certification-of-content/info.vue?macro=true";
import { default as index2S85Hiol8QMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/certification-of-content/input-content/index.vue?macro=true";
import { default as rejectPRgRybrB6OMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/certification-of-content/input-content/reject.vue?macro=true";
import { default as addmp01Zq3F9lMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/contact/add.vue?macro=true";
import { default as indexeVISc6WKFfMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/contact/index.vue?macro=true";
import { default as formXPef6ayVU7Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/coupon/storehub/form.vue?macro=true";
import { default as indext9bn0BLuueMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/coupon/storehub/index.vue?macro=true";
import { default as daldal6Ps47vml6pMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/daldal.vue?macro=true";
import { default as editEVOZd0GTqMMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/edit.vue?macro=true";
import { default as _91id_93N7PBQdz28iMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/event/[id].vue?macro=true";
import { default as faqfSAQosf3NyMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/faq.vue?macro=true";
import { default as indexrGAS6YLqOtMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/index.vue?macro=true";
import { default as klassesmdAYn0D2o4Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/klasses.vue?macro=true";
import { default as _91id_93bCI08ShhKXMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/notice/[id].vue?macro=true";
import { default as indexXdIBl3E6jkMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/notice/index.vue?macro=true";
import { default as _91code_93oed4ApDqovMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/product/detail/[code].vue?macro=true";
import { default as monthly_45pay1OlMoKI9REMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/product/monthly-pay.vue?macro=true";
import { default as _91id_93JxoXoLdE47Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/purchases/[id].vue?macro=true";
import { default as indexOZzpIyRFsvMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/purchases/index.vue?macro=true";
import { default as _91id_93nIQ4MdayEZMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/report/[id].vue?macro=true";
import { default as detailk9Qp9bIbmPMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/report/detail.vue?macro=true";
import { default as indexZd3NnkLfeWMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/report/index.vue?macro=true";
import { default as input_45addressdIttWiitATMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/report/input-address.vue?macro=true";
import { default as loading2BBpVuodNBMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/report/loading.vue?macro=true";
import { default as result0RwPnuozFEMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/result.vue?macro=true";
import { default as settingTmbU9B8cWEMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/setting.vue?macro=true";
import { default as storehubLKVpUqytr2Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/storehub.vue?macro=true";
import { default as alertuyMurJrdUzMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/withdrawal/alert.vue?macro=true";
import { default as indexmzBemC8xIyMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/withdrawal/index.vue?macro=true";
import { default as _failtIyJjxKI9FMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/_fail.vue?macro=true";
import { default as _success89FlDS8UPhMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/_success.vue?macro=true";
import { default as _91uuid_93NiIzvAYJNiMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/[uuid].vue?macro=true";
import { default as index4VmQsinSDCMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/index.vue?macro=true";
import { default as paying9nvgLevjfZMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/paying.vue?macro=true";
import { default as resultsiqSkCR131Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/result.vue?macro=true";
import { default as _91id_93gzFKj9CDVLMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/planner/[id].vue?macro=true";
import { default as calendartntAOVRhfeMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/planner/calendar.vue?macro=true";
import { default as defaultHTqEoOgLYsMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/planner/default.vue?macro=true";
import { default as _91id_93okQ7PYN8oFMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/planner/detail/[id].vue?macro=true";
import { default as indexsBZGoF0vZRMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/planner/index.vue?macro=true";
import { default as editkN4iC2HGaNMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/sales/edit.vue?macro=true";
import { default as indexCJ0eT0S1xeMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/sales/index.vue?macro=true";
import { default as zzimKyTXtmnlQ3Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/sales/zzim.vue?macro=true";
import { default as zutiWm5UPIokXVMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/app/type-indicator/zuti.vue?macro=true";
import { default as kakaon9uLtiM7wyMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/kakao.vue?macro=true";
import { default as indexxCVerPDFvJMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/login/index.vue?macro=true";
import { default as verify_45phoneUEw0rKjW3rMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/login/verify-phone.vue?macro=true";
import { default as indextV9ueBjMmFMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/index.vue?macro=true";
import { default as marketingtv58PN0mu9Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/marketing.vue?macro=true";
import { default as privacy_45agreebUq1PLIRaqMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/privacy-agree.vue?macro=true";
import { default as privacysdncWExpRXMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/privacy.vue?macro=true";
import { default as terms3QEfryZVUaMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/terms.vue?macro=true";
import { default as third_45terms_45agreeTkbFSO0klTMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/third-terms-agree.vue?macro=true";
import { default as input_45infofiIQTNKrmtMeta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/input-info.vue?macro=true";
import { default as indexm0OrEWR1b9Meta } from "/home/runner/work/zipup-webapp/zipup-webapp/pages/index.vue?macro=true";
export default [
  {
    name: entryFAEYo0Hgl5Meta?.name ?? "__native-entry",
    path: entryFAEYo0Hgl5Meta?.path ?? "/__native/entry",
    meta: entryFAEYo0Hgl5Meta || {},
    alias: entryFAEYo0Hgl5Meta?.alias || [],
    redirect: entryFAEYo0Hgl5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/__native/entry.vue").then(m => m.default || m)
  },
  {
    name: paymentmzYKAfEhRsMeta?.name ?? "__native-payment",
    path: paymentmzYKAfEhRsMeta?.path ?? "/__native/payment",
    meta: paymentmzYKAfEhRsMeta || {},
    alias: paymentmzYKAfEhRsMeta?.alias || [],
    redirect: paymentmzYKAfEhRsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/__native/payment.vue").then(m => m.default || m)
  },
  {
    name: updateCCbkFr5AbhMeta?.name ?? "__native-update",
    path: updateCCbkFr5AbhMeta?.path ?? "/__native/update",
    meta: updateCCbkFr5AbhMeta || {},
    alias: updateCCbkFr5AbhMeta?.alias || [],
    redirect: updateCCbkFr5AbhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/__native/update.vue").then(m => m.default || m)
  },
  {
    name: queryPARyOvqm8AMeta?.name ?? "4865-query",
    path: queryPARyOvqm8AMeta?.path ?? "/4865/query",
    meta: queryPARyOvqm8AMeta || {},
    alias: queryPARyOvqm8AMeta?.alias || [],
    redirect: queryPARyOvqm8AMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/4865/query.vue").then(m => m.default || m)
  },
  {
    name: __monthlyKPQzZNCxNqMeta?.name ?? "app-__monthly",
    path: __monthlyKPQzZNCxNqMeta?.path ?? "/app/__monthly",
    meta: __monthlyKPQzZNCxNqMeta || {},
    alias: __monthlyKPQzZNCxNqMeta?.alias || [],
    redirect: __monthlyKPQzZNCxNqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/__monthly.vue").then(m => m.default || m)
  },
  {
    name: __payment8c2oTPaR2qMeta?.name ?? "app-__payment",
    path: __payment8c2oTPaR2qMeta?.path ?? "/app/__payment",
    meta: __payment8c2oTPaR2qMeta || {},
    alias: __payment8c2oTPaR2qMeta?.alias || [],
    redirect: __payment8c2oTPaR2qMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/__payment.vue").then(m => m.default || m)
  },
  {
    name: index8JJmO0nkDVMeta?.name ?? "app-__preview",
    path: index8JJmO0nkDVMeta?.path ?? "/app/__preview",
    meta: index8JJmO0nkDVMeta || {},
    alias: index8JJmO0nkDVMeta?.alias || [],
    redirect: index8JJmO0nkDVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/__preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexl4dVqCHEU5Meta?.name ?? "app-benefits-card",
    path: indexl4dVqCHEU5Meta?.path ?? "/app/benefits/card",
    meta: {...(indexl4dVqCHEU5Meta || {}), ...{"middleware":"auth"}},
    alias: indexl4dVqCHEU5Meta?.alias || [],
    redirect: indexl4dVqCHEU5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/card/index.vue").then(m => m.default || m)
  },
  {
    name: landingawxZfdUZkWMeta?.name ?? "app-benefits-card-landing",
    path: landingawxZfdUZkWMeta?.path ?? "/app/benefits/card/landing",
    meta: {...(landingawxZfdUZkWMeta || {}), ...{"layout":"full"}},
    alias: landingawxZfdUZkWMeta?.alias || [],
    redirect: landingawxZfdUZkWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/card/landing.vue").then(m => m.default || m)
  },
  {
    name: membership7yQoP0k604Meta?.name ?? "app-benefits-card-membership",
    path: membership7yQoP0k604Meta?.path ?? "/app/benefits/card/membership",
    meta: membership7yQoP0k604Meta || {},
    alias: membership7yQoP0k604Meta?.alias || [],
    redirect: membership7yQoP0k604Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/card/membership.vue").then(m => m.default || m)
  },
  {
    name: index2HMrHOJWJPMeta?.name ?? "app-benefits",
    path: index2HMrHOJWJPMeta?.path ?? "/app/benefits",
    meta: index2HMrHOJWJPMeta || {},
    alias: index2HMrHOJWJPMeta?.alias || [],
    redirect: index2HMrHOJWJPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CIwI4xzjmrMeta?.name ?? "app-benefits-monthly-pay-add-id",
    path: _91id_93CIwI4xzjmrMeta?.path ?? "/app/benefits/monthly-pay/add/:id()",
    meta: _91id_93CIwI4xzjmrMeta || {},
    alias: _91id_93CIwI4xzjmrMeta?.alias || [],
    redirect: _91id_93CIwI4xzjmrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/add/[id].vue").then(m => m.default || m)
  },
  {
    name: indexN5m5xyROCRMeta?.name ?? "app-benefits-monthly-pay-add",
    path: indexN5m5xyROCRMeta?.path ?? "/app/benefits/monthly-pay/add",
    meta: {...(indexN5m5xyROCRMeta || {}), ...{"middleware":"auth"}},
    alias: indexN5m5xyROCRMeta?.alias || [],
    redirect: indexN5m5xyROCRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/add/index.vue").then(m => m.default || m)
  },
  {
    name: contactA0rHPUFxnIMeta?.name ?? "app-benefits-monthly-pay-contact",
    path: contactA0rHPUFxnIMeta?.path ?? "/app/benefits/monthly-pay/contact",
    meta: contactA0rHPUFxnIMeta || {},
    alias: contactA0rHPUFxnIMeta?.alias || [],
    redirect: contactA0rHPUFxnIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/contact.vue").then(m => m.default || m)
  },
  {
    name: faqg6OAt6xUEvMeta?.name ?? "app-benefits-monthly-pay-faq",
    path: faqg6OAt6xUEvMeta?.path ?? "/app/benefits/monthly-pay/faq",
    meta: {...(faqg6OAt6xUEvMeta || {}), ...{"middleware":"auth"}},
    alias: faqg6OAt6xUEvMeta?.alias || [],
    redirect: faqg6OAt6xUEvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/faq.vue").then(m => m.default || m)
  },
  {
    name: indexZeQ2COaghRMeta?.name ?? "app-benefits-monthly-pay",
    path: indexZeQ2COaghRMeta?.path ?? "/app/benefits/monthly-pay",
    meta: {...(indexZeQ2COaghRMeta || {}), ...{"middleware":"auth"}},
    alias: indexZeQ2COaghRMeta?.alias || [],
    redirect: indexZeQ2COaghRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/index.vue").then(m => m.default || m)
  },
  {
    name: contractyHFiSrcEgYMeta?.name ?? "app-benefits-monthly-pay-input-contract",
    path: contractyHFiSrcEgYMeta?.path ?? "/app/benefits/monthly-pay/input/contract",
    meta: {...(contractyHFiSrcEgYMeta || {}), ...{"middleware":"auth"}},
    alias: contractyHFiSrcEgYMeta?.alias || [],
    redirect: contractyHFiSrcEgYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/input/contract.vue").then(m => m.default || m)
  },
  {
    name: loadingBl8856E5lwMeta?.name ?? "app-benefits-monthly-pay-input-loading",
    path: loadingBl8856E5lwMeta?.path ?? "/app/benefits/monthly-pay/input/loading",
    meta: loadingBl8856E5lwMeta || {},
    alias: loadingBl8856E5lwMeta?.alias || [],
    redirect: loadingBl8856E5lwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/input/loading.vue").then(m => m.default || m)
  },
  {
    name: _91id_93G1xk023QKaMeta?.name ?? "app-benefits-monthly-pay-transfer-id",
    path: _91id_93G1xk023QKaMeta?.path ?? "/app/benefits/monthly-pay/transfer/:id()",
    meta: _91id_93G1xk023QKaMeta || {},
    alias: _91id_93G1xk023QKaMeta?.alias || [],
    redirect: _91id_93G1xk023QKaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/monthly-pay/transfer/[id].vue").then(m => m.default || m)
  },
  {
    name: completeR3nZJ4egBIMeta?.name ?? "app-benefits-registry-transcript-complete",
    path: completeR3nZJ4egBIMeta?.path ?? "/app/benefits/registry-transcript/complete",
    meta: {...(completeR3nZJ4egBIMeta || {}), ...{"middleware":"auth"}},
    alias: completeR3nZJ4egBIMeta?.alias || [],
    redirect: completeR3nZJ4egBIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/registry-transcript/complete.vue").then(m => m.default || m)
  },
  {
    name: indexfHFkVPRmYJMeta?.name ?? "app-benefits-registry-transcript",
    path: indexfHFkVPRmYJMeta?.path ?? "/app/benefits/registry-transcript",
    meta: {...(indexfHFkVPRmYJMeta || {}), ...{"middleware":"auth"}},
    alias: indexfHFkVPRmYJMeta?.alias || [],
    redirect: indexfHFkVPRmYJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/registry-transcript/index.vue").then(m => m.default || m)
  },
  {
    name: input_45addressIaUwDTfvzwMeta?.name ?? "app-benefits-registry-transcript-input-address",
    path: input_45addressIaUwDTfvzwMeta?.path ?? "/app/benefits/registry-transcript/input-address",
    meta: input_45addressIaUwDTfvzwMeta || {},
    alias: input_45addressIaUwDTfvzwMeta?.alias || [],
    redirect: input_45addressIaUwDTfvzwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/benefits/registry-transcript/input-address.vue").then(m => m.default || m)
  },
  {
    name: indextuPefS0LXDMeta?.name ?? "app-coupons-id",
    path: indextuPefS0LXDMeta?.path ?? "/app/coupons/:id()",
    meta: {...(indextuPefS0LXDMeta || {}), ...{"layout":"full"}},
    alias: indextuPefS0LXDMeta?.alias || [],
    redirect: indextuPefS0LXDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/coupons/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index3Lp9tMMLIkMeta?.name ?? "app-home",
    path: index3Lp9tMMLIkMeta?.path ?? "/app/home",
    meta: index3Lp9tMMLIkMeta || {},
    alias: index3Lp9tMMLIkMeta?.alias || [],
    redirect: index3Lp9tMMLIkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/home/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MICKojKl8xMeta?.name ?? "app-home-posts-id",
    path: _91id_93MICKojKl8xMeta?.path ?? "/app/home/posts/:id()",
    meta: _91id_93MICKojKl8xMeta || {},
    alias: _91id_93MICKojKl8xMeta?.alias || [],
    redirect: _91id_93MICKojKl8xMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/home/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: indexrRTRKXaVIZMeta?.name ?? "app-home-posts",
    path: indexrRTRKXaVIZMeta?.path ?? "/app/home/posts",
    meta: indexrRTRKXaVIZMeta || {},
    alias: indexrRTRKXaVIZMeta?.alias || [],
    redirect: indexrRTRKXaVIZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/home/posts/index.vue").then(m => m.default || m)
  },
  {
    name: slide3hNmF7rsSDMeta?.name ?? "app-home-posts-slide",
    path: slide3hNmF7rsSDMeta?.path ?? "/app/home/posts/slide",
    meta: slide3hNmF7rsSDMeta || {},
    alias: slide3hNmF7rsSDMeta?.alias || [],
    redirect: slide3hNmF7rsSDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/home/posts/slide.vue").then(m => m.default || m)
  },
  {
    name: indexGhB8o4rKUmMeta?.name ?? "app",
    path: indexGhB8o4rKUmMeta?.path ?? "/app",
    meta: indexGhB8o4rKUmMeta || {},
    alias: indexGhB8o4rKUmMeta?.alias || [],
    redirect: indexGhB8o4rKUmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xH5ARBmOKsMeta?.name ?? "app-klass-id",
    path: _91id_93xH5ARBmOKsMeta?.path ?? "/app/klass/:id()",
    meta: _91id_93xH5ARBmOKsMeta || {},
    alias: _91id_93xH5ARBmOKsMeta?.alias || [],
    redirect: _91id_93xH5ARBmOKsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/klass/[id].vue").then(m => m.default || m)
  },
  {
    name: alarmd9nNzybKRGMeta?.name ?? "app-myzipup-alarm",
    path: alarmd9nNzybKRGMeta?.path ?? "/app/myzipup/alarm",
    meta: alarmd9nNzybKRGMeta || {},
    alias: alarmd9nNzybKRGMeta?.alias || [],
    redirect: alarmd9nNzybKRGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/alarm.vue").then(m => m.default || m)
  },
  {
    name: _91contentId_93RfcbsbNyx4Meta?.name ?? "app-myzipup-certification-of-content-contentId",
    path: _91contentId_93RfcbsbNyx4Meta?.path ?? "/app/myzipup/certification-of-content/:contentId()",
    meta: _91contentId_93RfcbsbNyx4Meta || {},
    alias: _91contentId_93RfcbsbNyx4Meta?.alias || [],
    redirect: _91contentId_93RfcbsbNyx4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/certification-of-content/[contentId].vue").then(m => m.default || m)
  },
  {
    name: indexrB6D0NRIBUMeta?.name ?? "app-myzipup-certification-of-content",
    path: indexrB6D0NRIBUMeta?.path ?? "/app/myzipup/certification-of-content",
    meta: {...(indexrB6D0NRIBUMeta || {}), ...{"middleware":["auth"]}},
    alias: indexrB6D0NRIBUMeta?.alias || [],
    redirect: indexrB6D0NRIBUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/certification-of-content/index.vue").then(m => m.default || m)
  },
  {
    name: infoSXRbnDVbxdMeta?.name ?? "app-myzipup-certification-of-content-info",
    path: infoSXRbnDVbxdMeta?.path ?? "/app/myzipup/certification-of-content/info",
    meta: infoSXRbnDVbxdMeta || {},
    alias: infoSXRbnDVbxdMeta?.alias || [],
    redirect: infoSXRbnDVbxdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/certification-of-content/info.vue").then(m => m.default || m)
  },
  {
    name: index2S85Hiol8QMeta?.name ?? "app-myzipup-certification-of-content-input-content",
    path: index2S85Hiol8QMeta?.path ?? "/app/myzipup/certification-of-content/input-content",
    meta: {...(index2S85Hiol8QMeta || {}), ...{"middleware":"auth"}},
    alias: index2S85Hiol8QMeta?.alias || [],
    redirect: index2S85Hiol8QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/certification-of-content/input-content/index.vue").then(m => m.default || m)
  },
  {
    name: rejectPRgRybrB6OMeta?.name ?? "app-myzipup-certification-of-content-input-content-reject",
    path: rejectPRgRybrB6OMeta?.path ?? "/app/myzipup/certification-of-content/input-content/reject",
    meta: rejectPRgRybrB6OMeta || {},
    alias: rejectPRgRybrB6OMeta?.alias || [],
    redirect: rejectPRgRybrB6OMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/certification-of-content/input-content/reject.vue").then(m => m.default || m)
  },
  {
    name: addmp01Zq3F9lMeta?.name ?? "app-myzipup-contact-add",
    path: addmp01Zq3F9lMeta?.path ?? "/app/myzipup/contact/add",
    meta: addmp01Zq3F9lMeta || {},
    alias: addmp01Zq3F9lMeta?.alias || [],
    redirect: addmp01Zq3F9lMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/contact/add.vue").then(m => m.default || m)
  },
  {
    name: indexeVISc6WKFfMeta?.name ?? "app-myzipup-contact",
    path: indexeVISc6WKFfMeta?.path ?? "/app/myzipup/contact",
    meta: indexeVISc6WKFfMeta || {},
    alias: indexeVISc6WKFfMeta?.alias || [],
    redirect: indexeVISc6WKFfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/contact/index.vue").then(m => m.default || m)
  },
  {
    name: formXPef6ayVU7Meta?.name ?? "app-myzipup-coupon-storehub-form",
    path: formXPef6ayVU7Meta?.path ?? "/app/myzipup/coupon/storehub/form",
    meta: formXPef6ayVU7Meta || {},
    alias: formXPef6ayVU7Meta?.alias || [],
    redirect: formXPef6ayVU7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/coupon/storehub/form.vue").then(m => m.default || m)
  },
  {
    name: indext9bn0BLuueMeta?.name ?? "app-myzipup-coupon-storehub",
    path: indext9bn0BLuueMeta?.path ?? "/app/myzipup/coupon/storehub",
    meta: indext9bn0BLuueMeta || {},
    alias: indext9bn0BLuueMeta?.alias || [],
    redirect: indext9bn0BLuueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/coupon/storehub/index.vue").then(m => m.default || m)
  },
  {
    name: daldal6Ps47vml6pMeta?.name ?? "app-myzipup-daldal",
    path: daldal6Ps47vml6pMeta?.path ?? "/app/myzipup/daldal",
    meta: {...(daldal6Ps47vml6pMeta || {}), ...{"layout":"full"}},
    alias: daldal6Ps47vml6pMeta?.alias || [],
    redirect: daldal6Ps47vml6pMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/daldal.vue").then(m => m.default || m)
  },
  {
    name: editEVOZd0GTqMMeta?.name ?? "app-myzipup-edit",
    path: editEVOZd0GTqMMeta?.path ?? "/app/myzipup/edit",
    meta: editEVOZd0GTqMMeta || {},
    alias: editEVOZd0GTqMMeta?.alias || [],
    redirect: editEVOZd0GTqMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93N7PBQdz28iMeta?.name ?? "app-myzipup-event-id",
    path: _91id_93N7PBQdz28iMeta?.path ?? "/app/myzipup/event/:id()",
    meta: {...(_91id_93N7PBQdz28iMeta || {}), ...{"middleware":"auth"}},
    alias: _91id_93N7PBQdz28iMeta?.alias || [],
    redirect: _91id_93N7PBQdz28iMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/event/[id].vue").then(m => m.default || m)
  },
  {
    name: faqfSAQosf3NyMeta?.name ?? "app-myzipup-faq",
    path: faqfSAQosf3NyMeta?.path ?? "/app/myzipup/faq",
    meta: {...(faqfSAQosf3NyMeta || {}), ...{"middleware":"auth"}},
    alias: faqfSAQosf3NyMeta?.alias || [],
    redirect: faqfSAQosf3NyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/faq.vue").then(m => m.default || m)
  },
  {
    name: indexrGAS6YLqOtMeta?.name ?? "app-myzipup",
    path: indexrGAS6YLqOtMeta?.path ?? "/app/myzipup",
    meta: indexrGAS6YLqOtMeta || {},
    alias: indexrGAS6YLqOtMeta?.alias || [],
    redirect: indexrGAS6YLqOtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/index.vue").then(m => m.default || m)
  },
  {
    name: klassesmdAYn0D2o4Meta?.name ?? "app-myzipup-klasses",
    path: klassesmdAYn0D2o4Meta?.path ?? "/app/myzipup/klasses",
    meta: klassesmdAYn0D2o4Meta || {},
    alias: klassesmdAYn0D2o4Meta?.alias || [],
    redirect: klassesmdAYn0D2o4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/klasses.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bCI08ShhKXMeta?.name ?? "app-myzipup-notice-id",
    path: _91id_93bCI08ShhKXMeta?.path ?? "/app/myzipup/notice/:id()",
    meta: _91id_93bCI08ShhKXMeta || {},
    alias: _91id_93bCI08ShhKXMeta?.alias || [],
    redirect: _91id_93bCI08ShhKXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXdIBl3E6jkMeta?.name ?? "app-myzipup-notice",
    path: indexXdIBl3E6jkMeta?.path ?? "/app/myzipup/notice",
    meta: indexXdIBl3E6jkMeta || {},
    alias: indexXdIBl3E6jkMeta?.alias || [],
    redirect: indexXdIBl3E6jkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/notice/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93oed4ApDqovMeta?.name ?? "app-myzipup-product-detail-code",
    path: _91code_93oed4ApDqovMeta?.path ?? "/app/myzipup/product/detail/:code()",
    meta: {...(_91code_93oed4ApDqovMeta || {}), ...{"middleware":["auth"],"layout":"full"}},
    alias: _91code_93oed4ApDqovMeta?.alias || [],
    redirect: _91code_93oed4ApDqovMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/product/detail/[code].vue").then(m => m.default || m)
  },
  {
    name: monthly_45pay1OlMoKI9REMeta?.name ?? "app-myzipup-product-monthly-pay",
    path: monthly_45pay1OlMoKI9REMeta?.path ?? "/app/myzipup/product/monthly-pay",
    meta: monthly_45pay1OlMoKI9REMeta || {},
    alias: monthly_45pay1OlMoKI9REMeta?.alias || [],
    redirect: monthly_45pay1OlMoKI9REMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/product/monthly-pay.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JxoXoLdE47Meta?.name ?? "app-myzipup-purchases-id",
    path: _91id_93JxoXoLdE47Meta?.path ?? "/app/myzipup/purchases/:id()",
    meta: _91id_93JxoXoLdE47Meta || {},
    alias: _91id_93JxoXoLdE47Meta?.alias || [],
    redirect: _91id_93JxoXoLdE47Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/purchases/[id].vue").then(m => m.default || m)
  },
  {
    name: indexOZzpIyRFsvMeta?.name ?? "app-myzipup-purchases",
    path: indexOZzpIyRFsvMeta?.path ?? "/app/myzipup/purchases",
    meta: indexOZzpIyRFsvMeta || {},
    alias: indexOZzpIyRFsvMeta?.alias || [],
    redirect: indexOZzpIyRFsvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/purchases/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nIQ4MdayEZMeta?.name ?? "app-myzipup-report-id",
    path: _91id_93nIQ4MdayEZMeta?.path ?? "/app/myzipup/report/:id()",
    meta: _91id_93nIQ4MdayEZMeta || {},
    alias: _91id_93nIQ4MdayEZMeta?.alias || [],
    redirect: _91id_93nIQ4MdayEZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/report/[id].vue").then(m => m.default || m)
  },
  {
    name: detailk9Qp9bIbmPMeta?.name ?? "app-myzipup-report-detail",
    path: detailk9Qp9bIbmPMeta?.path ?? "/app/myzipup/report/detail",
    meta: {...(detailk9Qp9bIbmPMeta || {}), ...{"middleware":["auth"],"layout":"full"}},
    alias: detailk9Qp9bIbmPMeta?.alias || [],
    redirect: detailk9Qp9bIbmPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/report/detail.vue").then(m => m.default || m)
  },
  {
    name: indexZd3NnkLfeWMeta?.name ?? "app-myzipup-report",
    path: indexZd3NnkLfeWMeta?.path ?? "/app/myzipup/report",
    meta: indexZd3NnkLfeWMeta || {},
    alias: indexZd3NnkLfeWMeta?.alias || [],
    redirect: indexZd3NnkLfeWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/report/index.vue").then(m => m.default || m)
  },
  {
    name: input_45addressdIttWiitATMeta?.name ?? "app-myzipup-report-input-address",
    path: input_45addressdIttWiitATMeta?.path ?? "/app/myzipup/report/input-address",
    meta: input_45addressdIttWiitATMeta || {},
    alias: input_45addressdIttWiitATMeta?.alias || [],
    redirect: input_45addressdIttWiitATMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/report/input-address.vue").then(m => m.default || m)
  },
  {
    name: loading2BBpVuodNBMeta?.name ?? "app-myzipup-report-loading",
    path: loading2BBpVuodNBMeta?.path ?? "/app/myzipup/report/loading",
    meta: loading2BBpVuodNBMeta || {},
    alias: loading2BBpVuodNBMeta?.alias || [],
    redirect: loading2BBpVuodNBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/report/loading.vue").then(m => m.default || m)
  },
  {
    name: result0RwPnuozFEMeta?.name ?? "app-myzipup-result",
    path: result0RwPnuozFEMeta?.path ?? "/app/myzipup/result",
    meta: result0RwPnuozFEMeta || {},
    alias: result0RwPnuozFEMeta?.alias || [],
    redirect: result0RwPnuozFEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/result.vue").then(m => m.default || m)
  },
  {
    name: settingTmbU9B8cWEMeta?.name ?? "app-myzipup-setting",
    path: settingTmbU9B8cWEMeta?.path ?? "/app/myzipup/setting",
    meta: settingTmbU9B8cWEMeta || {},
    alias: settingTmbU9B8cWEMeta?.alias || [],
    redirect: settingTmbU9B8cWEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/setting.vue").then(m => m.default || m)
  },
  {
    name: storehubLKVpUqytr2Meta?.name ?? "app-myzipup-storehub",
    path: storehubLKVpUqytr2Meta?.path ?? "/app/myzipup/storehub",
    meta: {...(storehubLKVpUqytr2Meta || {}), ...{"middleware":"auth"}},
    alias: storehubLKVpUqytr2Meta?.alias || [],
    redirect: storehubLKVpUqytr2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/storehub.vue").then(m => m.default || m)
  },
  {
    name: alertuyMurJrdUzMeta?.name ?? "app-myzipup-withdrawal-alert",
    path: alertuyMurJrdUzMeta?.path ?? "/app/myzipup/withdrawal/alert",
    meta: alertuyMurJrdUzMeta || {},
    alias: alertuyMurJrdUzMeta?.alias || [],
    redirect: alertuyMurJrdUzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/withdrawal/alert.vue").then(m => m.default || m)
  },
  {
    name: indexmzBemC8xIyMeta?.name ?? "app-myzipup-withdrawal",
    path: indexmzBemC8xIyMeta?.path ?? "/app/myzipup/withdrawal",
    meta: indexmzBemC8xIyMeta || {},
    alias: indexmzBemC8xIyMeta?.alias || [],
    redirect: indexmzBemC8xIyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/myzipup/withdrawal/index.vue").then(m => m.default || m)
  },
  {
    name: _failtIyJjxKI9FMeta?.name ?? "app-payment-_fail",
    path: _failtIyJjxKI9FMeta?.path ?? "/app/payment/_fail",
    meta: _failtIyJjxKI9FMeta || {},
    alias: _failtIyJjxKI9FMeta?.alias || [],
    redirect: _failtIyJjxKI9FMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/_fail.vue").then(m => m.default || m)
  },
  {
    name: _success89FlDS8UPhMeta?.name ?? "app-payment-_success",
    path: _success89FlDS8UPhMeta?.path ?? "/app/payment/_success",
    meta: _success89FlDS8UPhMeta || {},
    alias: _success89FlDS8UPhMeta?.alias || [],
    redirect: _success89FlDS8UPhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/_success.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93NiIzvAYJNiMeta?.name ?? "app-payment-uuid",
    path: _91uuid_93NiIzvAYJNiMeta?.path ?? "/app/payment/:uuid()",
    meta: _91uuid_93NiIzvAYJNiMeta || {},
    alias: _91uuid_93NiIzvAYJNiMeta?.alias || [],
    redirect: _91uuid_93NiIzvAYJNiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/[uuid].vue").then(m => m.default || m)
  },
  {
    name: index4VmQsinSDCMeta?.name ?? "app-payment",
    path: index4VmQsinSDCMeta?.path ?? "/app/payment",
    meta: index4VmQsinSDCMeta || {},
    alias: index4VmQsinSDCMeta?.alias || [],
    redirect: index4VmQsinSDCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/index.vue").then(m => m.default || m)
  },
  {
    name: paying9nvgLevjfZMeta?.name ?? "app-payment-paying",
    path: paying9nvgLevjfZMeta?.path ?? "/app/payment/paying",
    meta: {...(paying9nvgLevjfZMeta || {}), ...{"middleware":"payment"}},
    alias: paying9nvgLevjfZMeta?.alias || [],
    redirect: paying9nvgLevjfZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/paying.vue").then(m => m.default || m)
  },
  {
    name: resultsiqSkCR131Meta?.name ?? "app-payment-result",
    path: resultsiqSkCR131Meta?.path ?? "/app/payment/result",
    meta: {...(resultsiqSkCR131Meta || {}), ...{"middleware":"monthly-pay"}},
    alias: resultsiqSkCR131Meta?.alias || [],
    redirect: resultsiqSkCR131Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/payment/result.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gzFKj9CDVLMeta?.name ?? "app-planner-id",
    path: _91id_93gzFKj9CDVLMeta?.path ?? "/app/planner/:id()",
    meta: _91id_93gzFKj9CDVLMeta || {},
    alias: _91id_93gzFKj9CDVLMeta?.alias || [],
    redirect: _91id_93gzFKj9CDVLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/planner/[id].vue").then(m => m.default || m)
  },
  {
    name: calendartntAOVRhfeMeta?.name ?? "app-planner-calendar",
    path: calendartntAOVRhfeMeta?.path ?? "/app/planner/calendar",
    meta: calendartntAOVRhfeMeta || {},
    alias: calendartntAOVRhfeMeta?.alias || [],
    redirect: calendartntAOVRhfeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/planner/calendar.vue").then(m => m.default || m)
  },
  {
    name: defaultHTqEoOgLYsMeta?.name ?? "app-planner-default",
    path: defaultHTqEoOgLYsMeta?.path ?? "/app/planner/default",
    meta: defaultHTqEoOgLYsMeta || {},
    alias: defaultHTqEoOgLYsMeta?.alias || [],
    redirect: defaultHTqEoOgLYsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/planner/default.vue").then(m => m.default || m)
  },
  {
    name: _91id_93okQ7PYN8oFMeta?.name ?? "app-planner-detail-id",
    path: _91id_93okQ7PYN8oFMeta?.path ?? "/app/planner/detail/:id()",
    meta: _91id_93okQ7PYN8oFMeta || {},
    alias: _91id_93okQ7PYN8oFMeta?.alias || [],
    redirect: _91id_93okQ7PYN8oFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/planner/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexsBZGoF0vZRMeta?.name ?? "app-planner",
    path: indexsBZGoF0vZRMeta?.path ?? "/app/planner",
    meta: {...(indexsBZGoF0vZRMeta || {}), ...{"middleware":["auth","slide-transition"],"level":5,"layout":"navbar-full"}},
    alias: indexsBZGoF0vZRMeta?.alias || [],
    redirect: indexsBZGoF0vZRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/planner/index.vue").then(m => m.default || m)
  },
  {
    name: editkN4iC2HGaNMeta?.name ?? "app-sales-edit",
    path: editkN4iC2HGaNMeta?.path ?? "/app/sales/edit",
    meta: editkN4iC2HGaNMeta || {},
    alias: editkN4iC2HGaNMeta?.alias || [],
    redirect: editkN4iC2HGaNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/sales/edit.vue").then(m => m.default || m)
  },
  {
    name: indexCJ0eT0S1xeMeta?.name ?? "app-sales",
    path: indexCJ0eT0S1xeMeta?.path ?? "/app/sales",
    meta: indexCJ0eT0S1xeMeta || {},
    alias: indexCJ0eT0S1xeMeta?.alias || [],
    redirect: indexCJ0eT0S1xeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/sales/index.vue").then(m => m.default || m)
  },
  {
    name: zzimKyTXtmnlQ3Meta?.name ?? "app-sales-zzim",
    path: zzimKyTXtmnlQ3Meta?.path ?? "/app/sales/zzim",
    meta: zzimKyTXtmnlQ3Meta || {},
    alias: zzimKyTXtmnlQ3Meta?.alias || [],
    redirect: zzimKyTXtmnlQ3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/sales/zzim.vue").then(m => m.default || m)
  },
  {
    name: zutiWm5UPIokXVMeta?.name ?? "app-type-indicator-zuti",
    path: zutiWm5UPIokXVMeta?.path ?? "/app/type-indicator/zuti",
    meta: zutiWm5UPIokXVMeta || {},
    alias: zutiWm5UPIokXVMeta?.alias || [],
    redirect: zutiWm5UPIokXVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/app/type-indicator/zuti.vue").then(m => m.default || m)
  },
  {
    name: kakaon9uLtiM7wyMeta?.name ?? "auth-kakao",
    path: kakaon9uLtiM7wyMeta?.path ?? "/auth/kakao",
    meta: kakaon9uLtiM7wyMeta || {},
    alias: kakaon9uLtiM7wyMeta?.alias || [],
    redirect: kakaon9uLtiM7wyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/kakao.vue").then(m => m.default || m)
  },
  {
    name: indexxCVerPDFvJMeta?.name ?? "auth-login",
    path: indexxCVerPDFvJMeta?.path ?? "/auth/login",
    meta: indexxCVerPDFvJMeta || {},
    alias: indexxCVerPDFvJMeta?.alias || [],
    redirect: indexxCVerPDFvJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: verify_45phoneUEw0rKjW3rMeta?.name ?? "auth-login-verify-phone",
    path: verify_45phoneUEw0rKjW3rMeta?.path ?? "/auth/login/verify-phone",
    meta: verify_45phoneUEw0rKjW3rMeta || {},
    alias: verify_45phoneUEw0rKjW3rMeta?.alias || [],
    redirect: verify_45phoneUEw0rKjW3rMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/login/verify-phone.vue").then(m => m.default || m)
  },
  {
    name: indextV9ueBjMmFMeta?.name ?? "auth-register-agreements",
    path: indextV9ueBjMmFMeta?.path ?? "/auth/register/agreements",
    meta: indextV9ueBjMmFMeta || {},
    alias: indextV9ueBjMmFMeta?.alias || [],
    redirect: indextV9ueBjMmFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/index.vue").then(m => m.default || m)
  },
  {
    name: marketingtv58PN0mu9Meta?.name ?? "auth-register-agreements-marketing",
    path: marketingtv58PN0mu9Meta?.path ?? "/auth/register/agreements/marketing",
    meta: marketingtv58PN0mu9Meta || {},
    alias: marketingtv58PN0mu9Meta?.alias || [],
    redirect: marketingtv58PN0mu9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/marketing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45agreebUq1PLIRaqMeta?.name ?? "auth-register-agreements-privacy-agree",
    path: privacy_45agreebUq1PLIRaqMeta?.path ?? "/auth/register/agreements/privacy-agree",
    meta: privacy_45agreebUq1PLIRaqMeta || {},
    alias: privacy_45agreebUq1PLIRaqMeta?.alias || [],
    redirect: privacy_45agreebUq1PLIRaqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/privacy-agree.vue").then(m => m.default || m)
  },
  {
    name: privacysdncWExpRXMeta?.name ?? "auth-register-agreements-privacy",
    path: privacysdncWExpRXMeta?.path ?? "/auth/register/agreements/privacy",
    meta: privacysdncWExpRXMeta || {},
    alias: privacysdncWExpRXMeta?.alias || [],
    redirect: privacysdncWExpRXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/privacy.vue").then(m => m.default || m)
  },
  {
    name: terms3QEfryZVUaMeta?.name ?? "auth-register-agreements-terms",
    path: terms3QEfryZVUaMeta?.path ?? "/auth/register/agreements/terms",
    meta: terms3QEfryZVUaMeta || {},
    alias: terms3QEfryZVUaMeta?.alias || [],
    redirect: terms3QEfryZVUaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/terms.vue").then(m => m.default || m)
  },
  {
    name: third_45terms_45agreeTkbFSO0klTMeta?.name ?? "auth-register-agreements-third-terms-agree",
    path: third_45terms_45agreeTkbFSO0klTMeta?.path ?? "/auth/register/agreements/third-terms-agree",
    meta: third_45terms_45agreeTkbFSO0klTMeta || {},
    alias: third_45terms_45agreeTkbFSO0klTMeta?.alias || [],
    redirect: third_45terms_45agreeTkbFSO0klTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/agreements/third-terms-agree.vue").then(m => m.default || m)
  },
  {
    name: input_45infofiIQTNKrmtMeta?.name ?? "auth-register-input-info",
    path: input_45infofiIQTNKrmtMeta?.path ?? "/auth/register/input-info",
    meta: input_45infofiIQTNKrmtMeta || {},
    alias: input_45infofiIQTNKrmtMeta?.alias || [],
    redirect: input_45infofiIQTNKrmtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/auth/register/input-info.vue").then(m => m.default || m)
  },
  {
    name: indexm0OrEWR1b9Meta?.name ?? "index",
    path: indexm0OrEWR1b9Meta?.path ?? "/",
    meta: indexm0OrEWR1b9Meta || {},
    alias: indexm0OrEWR1b9Meta?.alias || [],
    redirect: indexm0OrEWR1b9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/zipup-webapp/zipup-webapp/pages/index.vue").then(m => m.default || m)
  }
]