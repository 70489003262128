export default defineNuxtPlugin(() => {
  return {
    provide: {
      nl2br: (text?: string | null) => {
        if (!text) {
          return ''
        }
        return text.trim().replace(/(?:\n\r|\n|\r)/g, '<br />')
      }
    }
  }
})

declare module '#app' {
  interface NuxtApp {
    $nl2br(text: string | null): string
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $nl2br(text: string | null): string
  }
}