import { defineStore } from 'pinia'
import { ApolloClient } from '@apollo/client'
import {
  GetAuthUserDocument,
  GetAuthUserQuery,
  GetAuthUserQueryVariables,
  ZuUserMarketingAgreeMethods,
  ZuUserOauthType,
  ZutiType,
} from '~/types/graphql'
import dayjs from 'dayjs'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    _user: null as GetAuthUserQuery['authUser'] | null,
    _movingDate: null as unknown as dayjs.Dayjs,
  }),

  getters: {
    user: (state) => state._user,
    movingDate: (state) => {
      return state._movingDate
        ? state._movingDate
        : state._user?.userTaskInput.filter(
            (v) =>
              v.inputKey === 'scheduledMoveDate' ||
              v.inputKey === 'desiredMoveDate',
          ) &&
          state._user?.userTaskInput.filter(
            (v) =>
              v.inputKey === 'scheduledMoveDate' ||
              v.inputKey === 'desiredMoveDate',
          ).length > 0
        ? dayjs(
            state._user?.userTaskInput
              .filter(
                (v) =>
                  v.inputKey === 'scheduledMoveDate' ||
                  v.inputKey === 'desiredMoveDate',
              )
              .sort((a, b) => {
                if (dayjs(b.updatedAt).diff(dayjs(a.updatedAt), 'm') === 0) {
                  return dayjs(b.updatedAt).diff(dayjs(a.updatedAt), 's')
                }
                return dayjs(b.updatedAt).diff(dayjs(a.updatedAt), 'm')
              })[0]?.inputValue,
          )
        : null
    },
  },

  actions: {
    setUserMarketingAt(marketingAt: Date) {
      if (this._user) {
        this._user.agreeMarketingAt = marketingAt
      }
    },
    setUserMarketingMethods(marketingMethods: ZuUserMarketingAgreeMethods[]) {
      if (this._user) {
        this._user.agreeMarketingMethods = marketingMethods
      }
    },
    setUserAlarmAt(marketingAt: Date) {
      if (this._user) {
        this._user.newSaleAlarmAcceptedAt = marketingAt
      }
    },
    clear() {
      this._user = null
    },

    setUser(user: GetAuthUserQuery['authUser'] | null) {
      this._user = user
    },

    setUserMovingDate(date: dayjs.Dayjs) {
      this._movingDate = date
    },

    async getOrSyncUser() {
      if (!this.user) {
        await this.syncUser()
      }
      return this.user
    },

    async syncUser() {
      const app = useNuxtApp()
      const apollo = app.$apolloProvider as ApolloClient<any>

      try {
        const { data } = await apollo.query<
          GetAuthUserQuery,
          GetAuthUserQueryVariables
        >({
          fetchPolicy: 'no-cache',
          query: GetAuthUserDocument,
        })
        this.setUser(data.authUser)

        if (data.authUser.type === ZuUserOauthType.Kakao) {
          app.$typedLocalStore.setItem('lastLoginMethod', 'kakao')
        } else if (data.authUser.type === ZuUserOauthType.Google) {
          app.$typedLocalStore.setItem('lastLoginMethod', 'google')
        } else if (data.authUser.type === ZuUserOauthType.Apple) {
          app.$typedLocalStore.setItem('lastLoginMethod', 'apple')
        }
      } catch (e) {
        this.setUser(null)
      }
      return this.user
    },
  },
})
