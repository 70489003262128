import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'

import { gotcha } from '~/utils/gotcha'

export type Gotcha = <T = any>(
  handler: () => T | null | undefined,
  defaultValue?: T | null | undefined
) => T | null | undefined

declare module '#app' {
  interface NuxtApp {
    $gotcha: Gotcha
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $gotcha: Gotcha
  }
}

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.provide('gotcha', gotcha)
})
