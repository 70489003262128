import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { useLoadingStore } from '~/store/loading'

export interface VueLoading {
  open: () => Promise<void>
  close: () => Promise<void>
}

declare module '#app' {
  interface NuxtApp {
    $loading: VueLoading
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $loading: VueLoading
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const ctx = useNuxtApp()

  const loadingStore = useLoadingStore(ctx.$pinia)

  nuxtApp.provide('loading', {
    open: () => loadingStore.open(),
    close: () => loadingStore.close(),
  })
})
