// 이름 변경 필요
// 기능 : 전세 안전 리포트 입력 -> 저장 후 페이지로 이동
// 전세 안전 리포트 만을 위한 플러그인을 인지하게 이름 변경

// 모든 변수명에 카멜 케이스, 대쉬가 어떻게 사용되는지 vue 공식 사이트에서 확인 필요

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()

  const isBottomSheetOpen = ref(false)

  // 로컬 스토리지에서 값을 확인하는 함수
  const checkLocalStorage = () => {
    const userSafetyReportInput = localStorage.getItem('userSafetyReportInput') // key : 전세 안전 리포트로 변경
    if (userSafetyReportInput) {
      const parsedInput = JSON.parse(userSafetyReportInput)
      if (parsedInput && Object.keys(parsedInput).length > 0) {
        return true
      }
    }
    return false
  }

  const openBottomSheet = () => {
    isBottomSheetOpen.value = true
  }

  // 사용자가 버튼을 눌렀을 때 호출하는 함수
  const handleButtonClick = (page: string) => {
    if (checkLocalStorage()) {
      openBottomSheet()
    } else {
      window.localStorage.removeItem('userSafetyReportInput')
      router.push(page)
    }
  }

  // 바텀시트 내에서 사용자의 액션 처리
  const handleBottomSheetAction = (page: string, status: boolean) => {
    if (!status) {
      window.localStorage.removeItem('userSafetyReportInput')
    }
    router.push(page)

    isBottomSheetOpen.value = false // 바텀시트를 닫음
  }

  nuxtApp.provide('safetyReportRouter', handleButtonClick)
  nuxtApp.provide('handleBottomSheetAction', handleBottomSheetAction)
  nuxtApp.provide('isBottomSheetOpen', isBottomSheetOpen)
})
