import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { DialogOptions, useDialogStore } from '~/store/dialog'

export interface VueDialog {
  (options: DialogOptions): Promise<boolean>
}

declare module '#app' {
  interface NuxtApp {
    $dialog: VueDialog
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $dialog: VueDialog
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const ctx = useNuxtApp()

  const dialogStore = useDialogStore(ctx.$pinia)

  nuxtApp.provide('dialog', (options: DialogOptions) => {
    return dialogStore.open(options)
  })
})
