import { ApolloClient } from '@apollo/client'
import decodeJwt from 'jwt-decode'
import Cookie from "universal-cookie"
import { ApolloProvider } from 'vue-apollo'
import { apolloHelperLogin, apolloHelperLogout, getRefreshToken, getToken } from '~/apollo/apollo-helper'
import { RefreshTokenDocument, RefreshTokenMutation, RefreshTokenMutationVariables } from '~/types/graphql'

const HW_REFRESH_TOKEN = 'zipup_retok'

interface InternalJwtToken {
  id: string
  role: string
  aud: string
  exp: number
}


declare module '#app' {
  interface NuxtApp {
    $auth: VueAuth
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $auth: VueAuth
  }
}

export interface VueAuth {
  readonly loggedIn: boolean
  token(): { accessToken: string, refreshToken: string | null } | null
  login(accessToken: string, refreshToken?: string | null): Promise<void>
  logout(): Promise<void>
}

export default defineNuxtPlugin(nuxtApp => {
  let timer = null as any | null
  const apolloClient = nuxtApp.$apolloProvider as ApolloClient<any>
  const ctx = useNuxtApp()

  function getAuthTokens(): {
    accessToken: string
    refreshToken: string | null
  } | null {
    const [
      accessToken,
      refreshToken
    ] = [
        getToken(),
        getRefreshToken()
      ]

    return accessToken
      ? {
        accessToken,
        refreshToken,
      }
      : null
  }

  async function login(
    accessToken: string,
    refreshToken?: string | null,
    options?: { keep?: boolean }
  ): Promise<void> {
    const accessTokenInfo = decodeJwt<InternalJwtToken>(accessToken)
    const refreshTokenInfo = refreshToken
      ? decodeJwt<InternalJwtToken>(refreshToken)
      : null

    if (
      accessTokenInfo.role ||
      (refreshTokenInfo &&
        (refreshTokenInfo.role || refreshTokenInfo.aud !== 'refresh'))
    ) {
      throw new Error('잘못된 토큰입니다.')
    }

    if (refreshToken && refreshTokenInfo) {
      apolloHelperLogin(accessToken, refreshToken)

      clearTimeout(timer)
      timer = setTimeout(
        () => refreshAuthTokens(refreshToken),
        Math.min(120000, refreshTokenInfo.exp * 1000 - Date.now() - 60000)
      )
    } else {
      apolloHelperLogin(accessToken)
    }

    if (ctx.$native.enabled()) {
      ctx.$native.persistCookies()
    }
  }

  async function logout() {
    apolloHelperLogout()

    if (ctx.$native.enabled()) {
      ctx.$native.persistCookies()
    }

    clearTimeout(timer)
    timer = null
  }

  async function refreshAuthTokens(refreshToken: string) {
    const data = await apolloClient
      .mutate<RefreshTokenMutation, RefreshTokenMutationVariables>({
        mutation: RefreshTokenDocument,
        variables: {
          refreshToken,
        },
      })
      .then(({ data }) => data)
    if (data?.token) {
      await login(data.token.accessToken, data.token.refreshToken)
    }
  }

  // initialize
  if (process.client) {
    const tokens = getAuthTokens()
    if (tokens) {
      try {
        const refreshToken = tokens.refreshToken
        const refreshTokenInfo = refreshToken
          ? decodeJwt<InternalJwtToken>(refreshToken)
          : null
        if (refreshToken && refreshTokenInfo) {
          timer = setTimeout(
            () => refreshAuthTokens(refreshToken),
            Math.min(120000, refreshTokenInfo.exp * 1000 - Date.now() - 60000)
          )
        }
      } catch (e) {
        logout()
      }
    }
  }

  const auth: VueAuth = {
    get loggedIn() {
      return !!getAuthTokens()
    },
    token: getAuthTokens,
    login,
    logout,
  }

  nuxtApp.provide('auth', auth)
})
