import { defineStore } from 'pinia'

export const useLoadingStore = defineStore({
  id: 'loading',
  state: () => ({
    _open: false,
  }),
  getters: {
    opened: (state) => state._open,
  },
  actions: {
    open() {
      this._open = true
    },
    close() {
      this._open = false
    },
  },
})
