import * as amplitude from '@amplitude/analytics-browser'
import { format } from 'date-fns'
import { useAuthStore } from '~/store/auth'

export default defineNuxtPlugin((nuxtApp) => {
  const conig = useRuntimeConfig()
  const app = useNuxtApp()

  amplitude.init(conig.public.AMPLITUDE_API_KEY, {
    autocapture: true,
  })

  const authStore = useAuthStore()

  return {
    provide: {
      amplitude,
      amplitudeIdentify: async () => {
        const device = app.$native.enabled()
          ? await app.$native.device()
          : 'none'

        const instance = new amplitude.Identify()
        instance.set('signup_date', format(new Date(), 'yyyy-MM-dd'))
        instance.set('signup_Y', format(new Date(), 'yyyy'))
        instance.set('signup_M', format(new Date(), 'MM'))
        instance.set('signup_D', format(new Date(), 'dd'))
        instance.set('job', authStore.user?.job ?? 'none')
        instance.set('age', authStore.user?.age ?? 'none')
        instance.set('gender', authStore.user?.gender ?? 'none')
        instance.set(
          'new_service_alarm_accepted_at',
          authStore.user?.newServiceAlarmAcceptedAt ?? 'none',
        )
        instance.set(
          'new_sale_alarm_accepted_at',
          authStore.user?.newSaleAlarmAcceptedAt ?? 'none',
        )
        instance.set(
          'agree_marketing_accepted_at',
          authStore.user?.agreeMarketingAt ?? 'none',
        )
        instance.set(
          'oauth_type',
          authStore.user?.cardBenefitsGrantedAt ?? 'none',
        )
        instance.set('device', device)
        instance.set(
          'card_benefits_granted_at',
          authStore.user?.cardBenefitsGrantedAt ?? 'none',
        )

        return amplitude.identify(instance, {
          user_id: `DEVD-ZIPUP-${authStore.user?.id}`,
        }).promise
      },
    },
  }
})
