import { defineStore } from 'pinia'

export interface DialogOptions {
  image?: string
  title?: string
  message: string
  confirmText?: string
  cancelText?: string
  hiddenClose?: boolean
  force?: boolean
}

interface DialogCallback {
  options: DialogOptions
  onClose: (result?: boolean) => void
}

export const useDialogStore = defineStore({
  id: 'dialog',
  state: () => ({
    dialogCallback: null as DialogCallback | null,
  }),
  getters: {
    options: (state) => state.dialogCallback?.options,
  },
  actions: {
    setDialogCallback(Dialog: DialogCallback | null) {
      this.dialogCallback = Dialog
    },
    open(options: DialogOptions) {
      if (this.dialogCallback) {
        this.dialogCallback.onClose(false)
      }
      return new Promise<boolean | string>((resolve) => {
        this.setDialogCallback({
          options,
          onClose: resolve,
        } as DialogCallback)
      })
    },
    close(confirmed?: boolean) {
      if (this.dialogCallback) {
        this.dialogCallback.onClose(confirmed)
      }
      this.setDialogCallback(null)
    },
  },
})
