// import {  } from 'vue-apollo'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createApolloProvider } from '@vue/apollo-option'
import { split } from 'postcss/lib/list'
import { DollarApollo } from 'vue-apollo/types/vue-apollo'
import { getToken } from '~/apollo/apollo-helper'
import { LOCALSTORAGE_TOKEN_KEY } from '~/contants'
// import { createUploadLink } from 'apollo-upload-client'
import { DialogOptions, useDialogStore } from '~/store/dialog'

export interface VueResolveGraphQLErrors {
  (
    error: any,
    dialogOptionsMap?: Record<string, DialogOptions>,
  ): Promise<string | null>
}

declare module '#app' {
  interface NuxtApp {
    $apollo: ApolloClient<any>
    $apolloProvider: ApolloClient<any>
    $resolveGraphQLErrors: VueResolveGraphQLErrors
    $graphQLErrorCode: (error: any) => string | null
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $apollo: ApolloClient<any>
    $apolloProvider: ApolloClient<any>
    $resolveGraphQLErrors: VueResolveGraphQLErrors
    $graphQLErrorCode: (error: any) => string | null
  }
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig()
  const ctx = useNuxtApp()
  const dialogStore = useDialogStore(ctx.$pinia)

  const cache = new InMemoryCache()

  const httpLink = new HttpLink({
    // You should use an absolute URL here
    uri: config.public.API_URL + '/graphql',
  })

  const authLink = setContext((_, { headers }) => {
    const token = getToken() ?? config.public.TOKEN

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const apolloClient = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
  })

  const apolloProvider = createApolloProvider({
    // clients: { apolloClient, xhrApolloClient },
    defaultClient: apolloClient,
  })
  nuxtApp.vueApp.use(apolloProvider)
  nuxtApp.provide('apolloProvider', apolloClient)

  function graphQLErrorCode(error: any): string | null {
    if (error.graphQLErrors && error.graphQLErrors.length) {
      for (const { code } of error.graphQLErrors) {
        if (code) {
          return code
        }
      }
    }
    return null
  }
  nuxtApp.provide('graphQLErrorCode', graphQLErrorCode)

  async function resolveGraphQLErrors(
    error: any,
    dialogOptionsMap: Record<string, DialogOptions> = {},
  ): Promise<string | null> {
    const messages = []
    if (error.graphQLErrors && error.graphQLErrors.length) {
      for (const { code, message } of error.graphQLErrors) {
        if (code && dialogOptionsMap[code]) {
          await dialogStore.open(dialogOptionsMap[code])
          return code
        }
        if (code) {
          await dialogStore.open({ message })
          return code
        }
        messages.push(message)
      }
    }

    if (config.public.NODE_ENV !== 'production') {
      if (
        error.networkError &&
        error.networkError.result &&
        error.networkError.result.errors &&
        error.networkError.result.errors.length
      ) {
        error.networkError.result.errors.forEach(
          ({ message }: { message: string }) => messages.push(message),
        )
      }
    }

    if (messages.length === 0) {
      console.warn(error)
      messages.push(
        '문제가 발생했습니다. 😭\n잠시 후에 다시 시도해주세요. 문제가 반복되면 고객센터에 문의주세요.',
      )
    }

    await dialogStore.open({ message: messages.join('\n') })
    return null
  }
  nuxtApp.provide('resolveGraphQLErrors', resolveGraphQLErrors)
})
